
// 
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
    > a {
        color: var(--#{$prefix}body-color);
    }
    +.breadcrumb-item {
        &::before {
            font-family: "Font Awesome 5 Free";
        }
    }
}

// RTL

[dir="rtl"] {
    .breadcrumb-item {
        +.breadcrumb-item {
            padding-right: $breadcrumb-item-padding-x;

            &::before {
            font-family: "Font Awesome 5 Free";
            padding-left: 0;
                content: "\f105" !important;
            }
        }
    }

}